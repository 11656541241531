export const LOGIN_INCIDENTS =  'LOGIN_INCIDENTS'
export const LOGIN_INCIDENTS_SUCCESS = 'LOGIN_INCIDENTS_SUCCESS'
export const LOGIN_INCIDENTS_ERROR = 'LOGIN_INCIDENTS_ERROR'
export const LOGIN_INCIDENTS_CLEAR = 'LOGIN_INCIDENTS_CLEAR'

export const CHANGE_INCIDENTS = 'CHANGE_INCIDENTS'
export const CHANGE_INCIDENTS_SUCCESS = 'CHANGE_INCIDENTS_SUCCESS'
export const CHANGE_INCIDENTS_ERROR = 'CHANGE_INCIDENTS_ERROR'


