export default {
  IDENTITY_SCAN: {
    GET_DETAILS_TABLE: {
      INIT: 'GET_TABLE_DETAILS_INIT',
      SUCCESS: 'GET_TABLE_DETAILS_SUCCESS',
      ERROR: 'GET_TABLE_DETAILS_ERROR',
      CLEAR: 'GET_TABLE_DETAILS_CLEAR'
    },
    DROPDOWN_API: {
      INIT: 'DROPDOWN_API_DETAILS_INIT',
      SUCCESS: 'DROPDOWN_API_DETAILS_SUCCESS',
      ERROR: 'DROPDOWN_API_DETAILS_ERROR',
      CLEAR: 'DROPDOWN_API_DETAILS_CLEAR'
    },
    POST_DETAILS: {
      INIT: 'IDENTITY_SCAN_INPUT_DETAILS_INIT',
      SUCCESS: 'IDENTITY_SCAN_INPUT_DETAILS_SUCCESS',
      ERROR: 'IDENTITY_SCAN_INPUT_DETAILS_ERROR',
      CLEAR: 'IDENTITY_SCAN_INPUT_DETAILS_CLEAR'
    },
    UPDATE_DETAILS: {
      INIT: 'UPDATE_DETAILS_DETAILS_INIT',
      SUCCESS: 'UPDATE_DETAILS_DETAILS_SUCCESS',
      ERROR: 'UPDATE_DETAILS_DETAILS_ERROR',
      CLEAR: 'UPDATE_DETAILS_DETAILS_CLEAR'
    },
    DELETE: {
      INIT: 'DELETE_DETAILS_INIT',
      SUCCESS: 'DELETE_DETAILS_SUCCESS',
      ERROR: 'DELETE_DETAILS_ERROR',
      CLEAR: 'DELETE_DETAILS_CLEAR'
    },
    GET_USER_TABLE: {
      INIT: 'IDENTITY_GET_TABLE_INIT',
      SUCCESS: 'IDENTITY_GET_TABLE_SUCCESS',
      ERROR: 'IDENTITY_GET_TABLE_ERROR'
    },
    SIGNUP: {
      INIT: 'IDENTITY_SCAN_SIGNUNP_INIT',
      SUCCESS: 'IDENTITY_SCAN_SIGNUNP_SUCCESS',
      ERROR: 'IDENTITY_SCAN_SIGNUNP_ERROR',
      CLEAR: 'IDENTITY_SCAN_SIGNUNP_CLEAR'
    }
  },
  SIGNUP: {
    USERDATA: {
      INIT: 'IDENTITY_SCAN_SIGNUNP_INIT',
      SUCCESS: 'IDENTITY_SCAN_SIGNUNP_SUCCESS',
      ERROR: 'IDENTITY_SCAN_SIGNUNP_ERROR',
      CLEAR: 'IDENTITY_SCAN_SIGNUNP_CLEAR'
    }
  },
  FILTER: {
    FILTER_LIST: {
      INIT: 'FILTER_LIST_INIT',
      SUCCESS: 'FILTER_LIST_SUCCESS',
      ERROR: 'FILTER_LIST_ERROR'
    },
    CHANNELS: {
      INIT: 'CHANNELS_FILTERS_INIT',
      SUCCESS: 'CHANNELS_FILTERS_SUCCESS',
      ERROR: 'CHANNELS_FILTERS_ERROR'
    },
    CATEGORIES: {
      INIT: 'CATEGORIES_FILTERS_INIT',
      SUCCESS: 'CATEGORIES_FILTERS_SUCCESS',
      ERROR: 'CATEGORIES_FILTERS_ERROR'
    },
    PUBLISHER: {
      INIT: 'PUBLISHER_FILTERS_INIT',
      SUCCESS: 'PUBLISHER_FILTERS_SUCCESS',
      ERROR: 'PUBLISHER_FILTERS_ERROR'
    },
    COUNTRIES: {
      INIT: 'COUNTRIES_FILTERS_INIT',
      SUCCESS: 'COUNTRIES_FILTERS_SUCCESS',
      ERROR: 'COUNTRIES_FILTERS_ERROR'
    },
    BRANDS: {
      INIT: 'BRANDS_FILTERS_INIT',
      SUCCESS: 'BRANDS_FILTERS_SUCCESS',
      ERROR: 'BRANDS_FILTERS_ERROR'
    },
    PRIORITIES: {
      INIT: 'PRIORITIES_FILTERS_INIT',
      SUCCESS: 'PRIORITIES_FILTERS_SUCCESS',
      ERROR: 'PRIORITIES_FILTERS_ERROR'
    },
    STATUS: {
      INIT: 'STATUS_FILTERS_INIT',
      SUCCESS: 'STATUS_FILTERS_SUCCESS',
      ERROR: 'STATUS_FILTERS_ERROR'
    },
    SUB_CHANNEL: {
      INIT: 'SUB_CHANNEL_FILTERS_INIT',
      SUCCESS: 'SUB_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'SUB_CHANNEL_FILTERS_ERROR'
    },
    MERCHANT_LAUNDERING_PUBLISHER_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_PUBLISHER_FILTER_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_PUBLISHER_FILTER_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_PUBLISHER_FILTER_ERROR'
    },
    MERCHANT_LAUNDERING_SUB_CHANNEL_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_SUB_CHANNEL_FILTERS_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_SUB_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_SUB_CHANNEL_FILTERS_ERROR'
    },
    MERCHANT_LAUNDERING_CATEGORY_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_CATEGORY_FILTERS_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_CATEGORY_FILTERS_ERROR'
    },
    MERCHANT_LAUNDERING_SUB_CATEGORY_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_SUB_CATEGORY_FILTERS_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_SUB_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_SUB_CATEGORY_FILTERS_ERROR',
      NEXT: 'MERCHANT_LAUNDERING_SUB_CATEGORY_FILTER_NEXT'
    },
    MERCHANT_LAUNDERING_COUNTRY_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_COUNTRY_FILTERS_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_COUNTRY_FILTERS_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_COUNTRY_FILTERS_ERROR'
    },
    MERCHANT_LAUNDERING_SUB_SUB_CATEGORY_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_SUB_SUB_CATEGORY_FILTER_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_SUB_SUB_CATEGORY_FILTER_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_SUB_SUB_CATEGORY_FILTER_ERROR'
    },
    MERCHANT_LAUNDERING_UPI_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_UPI_FILTER_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_UPI_FILTER_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_UPI_FILTER_ERROR'
    },

    MERCHANT_LAUNDERING_PUBLISHER_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_PUBLISHER_FILTER_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_PUBLISHER_FILTER_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_PUBLISHER_FILTER_ERROR'
    },
    MERCHANT_LAUNDERING_SUB_CHANNEL_FILTER: {
      INIT: 'MERCHANT_LAUNDERING_SUB_CHANNEL_FILTERS_INIT',
      SUCCESS: 'MERCHANT_LAUNDERING_SUB_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'MERCHANT_LAUNDERING_SUB_CHANNEL_FILTERS_ERROR'
    },

    //lea filters
    // ---------------------------------------------------------------------------------------?

   

    // ----------------------------------------------------------------------------------------------

    INVESTMENT_SCAMS_CATEGORY_FILTER: {
      INIT: 'INVESTMENT_SCAMS_CATEGORY_FILTERS_INIT',
      SUCCESS: 'INVESTMENT_SCAMS_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'INVESTMENT_SCAMS_CATEGORY_FILTERS_ERROR'
    },
    INVESTMENT_SCAMS_SUB_CATEGORY_FILTER: {
      INIT: 'INVESTMENT_SCAMS_SUB_CATEGORY_FILTERS_INIT',
      SUCCESS: 'INVESTMENT_SCAMS_SUB_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'INVESTMENT_SCAMS_SUB_CATEGORY_FILTERS_ERROR',
      NEXT: 'INVESTMENT_SCAMS_SUB_CATEGORY_FILTER_NEXT'
    },
    INVESTMENT_SCAMS_COUNTRY_FILTER: {
      INIT: 'INVESTMENT_SCAMS_COUNTRY_FILTERS_INIT',
      SUCCESS: 'INVESTMENT_SCAMS_COUNTRY_FILTERS_SUCCESS',
      ERROR: 'INVESTMENT_SCAMS_COUNTRY_FILTERS_ERROR'
    },
    INVESTMENT_SCAMS_SUB_SUB_CATEGORY_FILTER: {
      INIT: 'INVESTMENT_SCAMS_SUB_SUB_CATEGORY_FILTER_INIT',
      SUCCESS: 'INVESTMENT_SCAMS_SUB_SUB_CATEGORY_FILTER_SUCCESS',
      ERROR: 'INVESTMENT_SCAMS_SUB_SUB_CATEGORY_FILTER_ERROR'
    },
    INVESTMENT_SCAMS_UPI_FILTER: {
      INIT: 'INVESTMENT_SCAMS_UPI_FILTER_INIT',
      SUCCESS: 'INVESTMENT_SCAMS_UPI_FILTER_SUCCESS',
      ERROR: 'INVESTMENT_SCAMS_UPI_FILTER_ERROR'
    },

    AFFILIATE_MONITORING_PUBLISHER_FILTER: {
      INIT: 'AFFILIATE_MONITORING_PUBLISHER_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_PUBLISHER_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_PUBLISHER_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_CHANNEL_FILTER: {
      INIT: 'AFFILIATE_MONITORING_CHANNEL_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_CHANNEL_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_SUB_CHANNEL_FILTER: {
      INIT: 'AFFILIATE_MONITORING_SUB_CHANNEL_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_SUB_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_SUB_CHANNEL_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_CATEGORY_FILTER: {
      INIT: 'AFFILIATE_MONITORING_CATEGORY_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_CATEGORY_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_SUB_CATEGORY_FILTER: {
      INIT: 'AFFILIATE_MONITORING_SUB_CATEGORY_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_SUB_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_SUB_CATEGORY_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_KEYWORD_FILTER: {
      INIT: 'AFFILIATE_MONITORING_KEYWORD_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_KEYWORD_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_KEYWORD_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_DOMAIN_FILTER: {
      INIT: 'AFFILIATE_MONITORING_DOMAIN_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_DOMAIN_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_DOMAIN_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_PRIORITY_FILTER: {
      INIT: 'AFFILIATE_MONITORING_PRIORITY_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_PRIORITY_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_PRIORITY_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_STATUS_FILTER: {
      INIT: 'AFFILIATE_MONITORING_STATUS_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_STATUS_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_STATUS_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_BRAND_FILTER: {
      INIT: 'AFFILIATE_MONITORING_BRAND_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_BRAND_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_BRAND_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_COUNTRY_FILTER: {
      INIT: 'AFFILIATE_MONITORING_COUNTRY_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_COUNTRY_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_COUNTRY_FILTERS_ERROR'
    },
    AFFILIATE_MONITORING_LOCATION_FILTER: {
      INIT: 'AFFILIATE_MONITORING_LOCATION_FILTERS_INIT',
      SUCCESS: 'AFFILIATE_MONITORING_LOCATION_FILTERS_SUCCESS',
      ERROR: 'AFFILIATE_MONITORING_LOCATION_FILTERS_ERROR'
    },
    // ===========================================
    BRAND_MONITORING_PRIORITY_FILTER: {
      INIT: 'BRAND_MONITORING_PRIORITY_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_PRIORITY_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_PRIORITY_FILTERS_ERROR'
    },
    BRAND_MONITORING_STATUS_FILTER: {
      INIT: 'BRAND_MONITORING_STATUS_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_STATUS_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_STATUS_FILTERS_ERROR'
    },
    BRAND_MONITORING_COUNTRY_FILTER: {
      INIT: 'BRAND_MONITORING_COUNTRY_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_COUNTRY_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_COUNTRY_FILTERS_ERROR'
    },
    BRAND_MONITORING_CHANNEL_FILTER: {
      INIT: 'BRAND_MONITORING_CHANNEL_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_CHANNEL_FILTERS_ERROR'
    },
    BRAND_MONITORING_SUB_CHANNEL_FILTER: {
      INIT: 'BRAND_MONITORING_SUB_CHANNEL_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_SUB_CHANNEL_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_SUB_CHANNEL_FILTERS_ERROR'
    },
    BRAND_MONITORING_CATEGORY_FILTER: {
      INIT: 'BRAND_MONITORING_CATEGORY_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_CATEGORY_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_CATEGORY_FILTERS_ERROR'
    },
    BRAND_MONITORING_PUBLISHER_FILTER: {
      INIT: 'BRAND_MONITORING_PUBLISHER_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_PUBLISHER_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_PUBLISHER_FILTERS_ERROR'
    },
    BRAND_MONITORING_BRAND_FILTER: {
      INIT: 'BRAND_MONITORING_BRAND_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_BRAND_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_BRAND_FILTERS_ERROR'
    },
    BRAND_MONITORING_TAKEDOWN_STATUS_FILTER: {
      INIT: 'BRAND_MONITORING_TAKEDOWN_STATUS_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_TAKEDOWN_STATUS_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_TAKEDOWN_STATUS_FILTERS_ERROR'
    },
    BRAND_MONITORING_UPLOAD_DATE_FILTER: {
      INIT: 'BRAND_MONITORING_UPLOAD_DATE_FILTERS_INIT',
      SUCCESS: 'BRAND_MONITORING_UPLOAD_DATE_FILTERS_SUCCESS',
      ERROR: 'BRAND_MONITORING_UPLOAD_DATE_FILTERS_ERROR'
    }
  },

  LEAFILTER:{
    LEA_CUSTOMER_FILTER: {
      INIT: 'LEA_CUSTOMER_INIT',
      SUCCESS: 'LEA_CUSTOMER_SUCCESS',
      ERROR: 'LEA_CUSTOMER_ERROR'
    },
 
    LEA_PLATFORM_FILTER: {
      INIT: 'LEA_PLATFORM_FILTER_INIT',
      SUCCESS: 'LEA_PLATFORM_FILTER_SUCCESS',
      ERROR: 'LEA_PLATFORM_FILTER_ERROR'
    },
    LEA_KEYWORD_FILTER: {
      INIT: 'LEA_KEYWORD_FILTER_INIT',
      SUCCESS: 'LEA_KEYWORD_FILTER_SUCCESS',
      ERROR: 'LEA_KEYWORD_FILTER_ERROR'
    },

    LEA_POSTEDDATE: {
      INIT: 'LEA_POSTEDDATE_INIT',
      SUCCESS: 'LEA_POSTEDDATE_SUCCESS',
      ERROR: 'LEA_POSTEDDATE_ERROR'
    },
    LEA_USERID: {
      INIT: 'LEA_USERID_INIT',
      SUCCESS: 'LEA_USERID_SUCCESS',
      ERROR: 'LEA_USERID_ERROR'
    },
  },
  SIDE_DRAWER: {
    SET_SIDE_DRAWER: {
      SUCCESS: 'SET_SIDE_DRAWER'
    }
  },
  SEARCH_360_DEGREE: {
    GET_DETAILS_TABLE: {
      INIT: 'GET_360_DEGREE_TABLE_INIT',
      SUCCESS: 'GET_360_DEGREE_TABLE_SUCCESS',
      ERROR: 'GET_360_DEGREE_TABLE_ERROR',
      CLEAR: 'GET_360_DEGREE_TABLE_CLEAR'
    },
    POST_DETAILS: {
      INIT: 'UPLOAD_FILE_INIT',
      SUCCESS: 'UPLOAD_FILE_SUCCESS',
      ERROR: 'UPLOAD_FILE_ERROR',
      CLEAR: 'UPLOAD_FILE_CLEAR'
    }
  },
  BI_TICKET: {
    GET_DETAILS_TABLE: {
      INIT: 'GET_BI_TICKET_TABLE_INIT',
      SUCCESS: 'GET_BI_TICKET_TABLE_SUCCESS',
      ERROR: 'GET_BI_TICKET_TABLE_ERROR',
      CLEAR: 'GET_BI_TICKET_TABLE_CLEAR'
    },
    UPDATE_DATA: {
      INIT: 'BI_TICKET_UPDATE_INIT',
      SUCCESS: 'GET_BI_TICKET_UPDATE_SUCCESS',
      ERROR: 'GET_BI_TICKET_UPDATE_ERROR',
      CLEAR: 'GET_BI_TICKET_UPDATE_CLEAR'
    },
    SINGLE_TICKET: {
      INIT: 'SINGLE_TICKET_INIT',
      SUCCESS: 'GET_SINGLE_TICKET_SUCCESS',
      ERROR: 'GET_SINGLE_TICKET_ERROR',
      CLEAR: 'GET_SINGLE_TICKET_CLEAR'
    },
    DOWNLOAD_TICKET: {
      INIT: 'DOWNLOAD_TICKET_INIT',
      SUCCESS: 'DOWNLOAD_TICKET_SUCCESS',
      ERROR: 'DOWNLOAD_TICKET_ERROR',
      CLEAR: 'DOWNLOAD_TICKET_CLEAR'
    },
    GET_PRIORITY_LIST: {
      INIT: 'GET_PRIORITY_LIST_INIT',
      SUCCESS: 'GET_PRIORITY_LIST_SUCCESS',
      ERROR: 'GET_PRIORITY_LIST_ERROR',
      CLEAR: 'GET_PRIORITY_LIST_CLEAR'
    },
    GET_STATUS_LIST: {
      INIT: 'GET_STATUS_LIST_INIT',
      SUCCESS: 'GET_STATUS_LIST_SUCCESS',
      ERROR: 'GET_STATUS_LIST_ERROR',
      CLEAR: 'GET_STATUS_LIST_CLEAR'
    },
    GET_ASSIGNEE_LIST: {
      INIT: 'GET_ASSIGNEE_LIST_INIT',
      SUCCESS: 'GET_ASSIGNEE_LIST_SUCCESS',
      ERROR: 'GET_ASSIGNEE_LIST_ERROR',
      CLEAR: 'GET_ASSIGNEE_LIST_CLEAR'
    }
  },
  EXPORT_REPORT: {
    GET_DETAILS_TABLE: {
      INIT: 'EXPORT_REPORT_TABLE_INIT',
      SUCCESS: 'EXPORT_REPORT_TABLE_SUCCESS',
      ERROR: 'EXPORT_REPORT_TABLE_ERROR',
      REFRESH: 'EXPORT_REPORT_TABLE_REFRESH'
    },
    GENERATE_REPORT: {
      INIT: 'GENERATE_REPORT_INIT',
      SUCCESS: 'GENERATE_REPORT_SUCCESS',
      ERROR: 'GENERATE_REPORT_ERROR',
      CLEAR: 'GENERATE_REPORT_CLEAR'
    }
  },
  DIGITAL_FOOTPRINT: {
    GET_DETAILS_TABLE: {
      INIT: 'DIGITAL_FOOTPRINT_INIT',
      SUCCESS: 'DIGITAL_FOOTPRINT_SUCCESS',
      ERROR: 'DIGITAL_FOOTPRINT_ERROR',
      CLEAR: 'DIGITAL_FOOTPRINT_CLEAR'
    },
    DOWNLOAD_REPORT: {
      INIT: 'DOWNLOAD_REPORT_INIT',
      SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
      ERROR: 'DOWNLOAD_REPORT_ERROR',
      CLEAR: 'DOWNLOAD_REPORT_CLEAR'
    }
  },
  PACKAGE_MAPPING: {
    GET_DETAILS_TABLE: {
      INIT: 'PACKAGE_MAPPING_INIT',
      SUCCESS: 'PACKAGE_MAPPING_SUCCESS',
      ERROR: 'PACKAGE_MAPPING_ERROR',
      CLEAR: 'PACKAGE_MAPPING_CLEAR'
    },
    POST_DETAILS_PACKAGE: {
      INIT: 'POST_DETAILS_PACKAGE_INIT',
      SUCCESS: 'POST_DETAILS_PACKAGE_SUCCESS',
      ERROR: 'POST_DETAILS_PACKAGE_ERROR',
      CLEAR: 'POST_DETAILS_PACKAGE_CLEAR'
    },

    GET_USER_LIST: {
      INIT: 'GET_USER_INIT',
      SUCCESS: 'GET_USER_SUCCESS',
      ERROR: 'GET_USER_ERROR',
      CLEAR: 'GET_USER_CLEAR'
    },
    GET_PACKAGE_LIST: {
      INIT: 'GET_PACKAGE_INIT',
      SUCCESS: 'GET_PACKAGE_SUCCESS',
      ERROR: 'GET_PACKAGE_ERROR',
      CLEAR: 'GET_PACKAGE_CLEAR'
    },
    GET_FILTER_LIST: {
      INIT: 'GET_FILTER_INIT',
      SUCCESS: 'GET_FILTER_SUCCESS',
      ERROR: 'GET_FILTER_ERROR',
      CLEAR: 'GET_FILTER_CLEAR'
    },
    GETT_PACKAGE_MENU_LIST: {
      INIT: 'GETT_PACKAGE_MENU_INIT',
      SUCCESS: 'GETT_PACKAGE_MENU_SUCCESS',
      ERROR: 'GETT_PACKAGE_MENU_ERROR',
      CLEAR: 'GETT_PACKAGE_MENU_CLEAR'
    },
    POST_DETAILS: {
      INIT: 'POST_PACKAGE_DETAILS_INIT',
      SUCCESS: 'POST_PACKAGE_DETAILS_SUCCESS',
      ERROR: 'POST_PACKAGE_DETAILS_ERROR',
      CLEAR: 'POST_PACKAGE_DETAILS_CLEAR'
    },
    DELETE: {
      INIT: 'DELETE_PACKAGE_MAPPING_INIT',
      SUCCESS: 'DELETE_PACKAGE_MAPPING_SUCCESS',
      ERROR: 'DELETE_PACKAGE_MAPPING_ERROR',
      CLEAR: 'DELETE_PACKAGE_MAPPING_CLEAR'
    },
    GET_PACKAGE_DATA: {
      INIT: 'GET_PACKAGE_DATA_INIT',
      SUCCESS: 'GET_PACKAGE_DATA_SUCCESS',
      ERROR: 'GET_PACKAGE_DATA_ERROR',
      CLEAR: 'GET_PACKAGE_DATA_CLEAR'
    },
    GET_PACKAGE_INCIDENT_LIST: {
      INIT: 'GET_PACKAGE_INCIDENT_INIT',
      SUCCESS: 'GET_PACKAGE_INCIDENT_SUCCESS',
      ERROR: 'GET_PACKAGE_INCIDENT_ERROR',
      CLEAR: 'GET_PACKAGE_INCIDENT_CLEAR'
    },
    UPDATE_DETAILS: {
      INIT: 'UPDATE_PACKAGE_DETAILS_INIT',
      SUCCESS: 'UPDATE_PACKAGE_DETAILS_SUCCESS',
      ERROR: 'UPDATE_PACKAGE_DETAILS_ERROR',
      CLEAR: 'UPDATE_PACKAGE_DETAILS_CLEAR'
    }
  },

  CUSTOMER_REPORT_CONFIG: {
    GET_DETAILS_TABLE: {
      INIT: 'CUSTOMER_REPORT_INIT',
      SUCCESS: 'CUSTOMER_REPORT_SUCCESS',
      ERROR: 'CUSTOMER_REPORT_ERROR',
      CLEAR: 'CUSTOMER_REPORT_CLEAR'
    },
    // POST_DETAILS_PACKAGE: {
    //   INIT: 'POST_DETAILS_PACKAGE_INIT',
    //   SUCCESS: 'POST_DETAILS_PACKAGE_SUCCESS',
    //   ERROR: 'POST_DETAILS_PACKAGE_ERROR',
    //   CLEAR: 'POST_DETAILS_PACKAGE_CLEAR'
    // },

    // GET_USER_LIST: {
    //   INIT: 'GET_USER_INIT',
    //   SUCCESS: 'GET_USER_SUCCESS',
    //   ERROR: 'GET_USER_ERROR',
    //   CLEAR: 'GET_USER_CLEAR'
    // },
    // GET_PACKAGE_LIST: {
    //   INIT: 'GET_PACKAGE_INIT',
    //   SUCCESS: 'GET_PACKAGE_SUCCESS',
    //   ERROR: 'GET_PACKAGE_ERROR',
    //   CLEAR: 'GET_PACKAGE_CLEAR'
    // },
    // GET_FILTER_LIST: {
    //   INIT: 'GET_FILTER_INIT',
    //   SUCCESS: 'GET_FILTER_SUCCESS',
    //   ERROR: 'GET_FILTER_ERROR',
    //   CLEAR: 'GET_FILTER_CLEAR'
    // },
    // GETT_PACKAGE_MENU_LIST: {
    //   INIT: 'GETT_PACKAGE_MENU_INIT',
    //   SUCCESS: 'GETT_PACKAGE_MENU_SUCCESS',
    //   ERROR: 'GETT_PACKAGE_MENU_ERROR',
    //   CLEAR: 'GETT_PACKAGE_MENU_CLEAR'
    // },
    POST_DETAILS: {
      INIT: 'POST_CUSTOMER_DETAILS_INIT',
      SUCCESS: 'POST_CUSTOMER_DETAILS_SUCCESS',
      ERROR: 'POST_CUSTOMER_DETAILS_ERROR',
      CLEAR: 'POST_CUSTOMER_DETAILS_CLEAR'
    },
    DELETE: {
      INIT: 'DELETE_CUSTOMER_REPORT_INIT',
      SUCCESS: 'DELETE_CUSTOMER_REPORT_SUCCESS',
      ERROR: 'DELETE_CUSTOMER_REPORT_ERROR',
      CLEAR: 'DELETE_CUSTOMER_REPORT_CLEAR'
    }
    // GET_PACKAGE_DATA: {
    //   INIT: 'GET_PACKAGE_DATA_INIT',
    //   SUCCESS: 'GET_PACKAGE_DATA_SUCCESS',
    //   ERROR: 'GET_PACKAGE_DATA_ERROR',
    //   CLEAR: 'GET_PACKAGE_DATA_CLEAR'
    // },
    // GET_PACKAGE_INCIDENT_LIST: {
    //   INIT: 'GET_PACKAGE_INCIDENT_INIT',
    //   SUCCESS: 'GET_PACKAGE_INCIDENT_SUCCESS',
    //   ERROR: 'GET_PACKAGE_INCIDENT_ERROR',
    //   CLEAR: 'GET_PACKAGE_INCIDENT_CLEAR'
    // },
    // UPDATE_DETAILS: {
    //   INIT: 'UPDATE_PACKAGE_DETAILS_INIT',
    //   SUCCESS: 'UPDATE_PACKAGE_DETAILS_SUCCESS',
    //   ERROR: 'UPDATE_PACKAGE_DETAILS_ERROR',
    //   CLEAR: 'UPDATE_PACKAGE_DETAILS_CLEAR'
    // }
  },

  REDMINE_MAPPING: {
    GET_DETAILS_TABLE: {
      INIT: 'REDMINE_MAPPING_INIT',
      SUCCESS: 'REDMINE_MAPPING_SUCCESS',
      ERROR: 'REDMINE_MAPPING_ERROR',
      CLEAR: 'REDMINE_MAPPING_CLEAR'
    },
    GET_USER_DETAILS: {
      INIT: 'GET_DETAILS_TABLE_INIT',
      SUCCESS: 'GET_DETAILS_TABLE_SUCCESS',
      ERROR: 'GET_DETAILS_TABLE_ERROR',
      CLEAR: 'GET_DETAILS_TABLE_CLEAR'
    },
    GET_PROJECT_LIST: {
      INIT: 'GET_PROJECT_INIT',
      SUCCESS: 'GET_PROJECT_SUCCESS',
      ERROR: 'GET_PROJECT_ERROR',
      CLEAR: 'GET_PROJECT_CLEAR'
    },
    GET_MEMBERSHIP_LIST: {
      INIT: 'GET_MEMBERSHIP_INIT',
      SUCCESS: 'GET_MEMBERSHIP_SUCCESS',
      ERROR: 'GET_MEMBERSHIP_ERROR',
      CLEAR: 'GET_MEMBERSHIP_CLEAR'
    },
    GET_REDMINE_DATA: {
      INIT: 'GET_REDMINE_DATA_INIT',
      SUCCESS: 'GET_REDMINE_DATA_SUCCESS',
      ERROR: 'GET_REDMINE_DATA_ERROR',
      CLEAR: 'GET_REDMINE_DATA_CLEAR'
    },
    POST_DETAILS: {
      INIT: 'REDMINE_MAPPING_INPUT_DETAILS_INIT',
      SUCCESS: 'REDMINE_MAPPING_INPUT_DETAILS_SUCCESS',
      ERROR: 'REDMINE_MAPPING_INPUT_DETAILS_ERROR',
      CLEAR: 'REDMINE_MAPPING_INPUT_DETAILS_CLEAR'
    },
    UPDATE_DETAILS: {
      INIT: 'UPDATE_REDMINE_MAPPING_DETAILS_INIT',
      SUCCESS: 'UPDATE_REDMINE_MAPPING_DETAILS_SUCCESS',
      ERROR: 'UPDATE_REDMINE_MAPPING_DETAILS_ERROR',
      CLEAR: 'UPDATE_REDMINE_MAPPING_DETAILS_CLEAR'
    },
    DELETE: {
      INIT: 'DELETE_REDMINE_MAPPING_DETAILS_INIT',
      SUCCESS: 'DELETE_REDMINE_MAPPING_DETAILS_SUCCESS',
      ERROR: 'DELETE_REDMINE_MAPPING_DETAILS_ERROR',
      CLEAR: 'DELETE_REDMINE_MAPPING_DETAILS_CLEAR'
    }
  },
  DASHBOARD: {
    COUNT: {
      INIT: 'COUNT_INIT',
      SUCCESS: 'COUNT_SUCCESS',
      ERROR: 'COUNT_ERROR',
      CLEAR: 'COUNT_CLEAR'
    },
    INCIDENT_VOLUME: {
      INIT: 'INCIDENT_VOLUME_INIT',
      SUCCESS: 'INCIDENT_VOLUME_SUCCESS',
      ERROR: 'INCIDENT_VOLUME_ERROR',
      CLEAR: 'INCIDENT_VOLUME_CLEAR'
    },
    CASE_BY_COUNTRY: {
      INIT: 'CASE_BY_COUNTRY_INIT',
      SUCCESS: 'CASE_BY_COUNTRY_SUCCESS',
      ERROR: 'CASE_BY_COUNTRY_ERROR',
      CLEAR: 'CASE_BY_COUNTRY_CLEAR'
    },
    CASE_BY_CHANNEL: {
      INIT: 'CASE_BY_CHANNEL_INIT',
      SUCCESS: 'CASE_BY_CHANNEL_SUCCESS',
      ERROR: 'CASE_BY_CHANNEL_ERROR',
      CLEAR: 'CASE_BY_CHANNEL_CLEAR'
    },
    CASE_BY_SUB_CHANNEL: {
      INIT: 'CASE_BY_SUB_CHANNEL_INIT',
      SUCCESS: 'CASE_BY_SUB_CHANNEL_SUCCESS',
      ERROR: 'CASE_BY_SUB_CHANNEL_ERROR',
      CLEAR: 'CASE_BY_SUB_CHANNEL_CLEAR'
    },
    TOP_LOCATION: {
      INIT: 'TOP_LOCATION_INIT',
      SUCCESS: 'TOP_LOCATION_SUCCESS',
      ERROR: 'TOP_LOCATION_ERROR',
      CLEAR: 'TOP_LOCATION_CLEAR'
    },
    CASE_BY_CATEGORY: {
      INIT: 'CASE_BY_CATEGORY_INIT',
      SUCCESS: 'CASE_BY_CATEGORY_SUCCESS',
      ERROR: 'CASE_BY_CATEGORY_ERROR',
      CLEAR: 'CASE_BY_CATEGORY_CLEAR'
    },
    CASE_BY_SUB_CATEGORY: {
      INIT: 'CASE_BY_SUB_CATEGORY_INIT',
      SUCCESS: 'CASE_BY_SUB_CATEGORY_SUCCESS',
      ERROR: 'CASE_BY_SUB_CATEGORY_ERROR',
      CLEAR: 'CASE_BY_SUB_CATEGORY_CLEAR'
    },
    CASE_BY_PUBLISHER: {
      INIT: 'CASE_BY_PUBLISHER_INIT',
      SUCCESS: 'CASE_BY_PUBLISHER_SUCCESS',
      ERROR: 'CASE_BY_PUBLISHER_ERROR',
      CLEAR: 'CASE_BY_PUBLISHER_CLEAR'
    },
    TOP_KEYWORD: {
      INIT: 'TOP_KEYWORD_INIT',
      SUCCESS: 'TOP_KEYWORD_SUCCESS',
      ERROR: 'TOP_KEYWORD_ERROR',
      CLEAR: 'TOP_KEYWORD_CLEAR'
    },
    TOP_DOMAIN: {
      INIT: 'TOP_DOMAIN_INIT',
      SUCCESS: 'TOP_DOMAIN_SUCCESS',
      ERROR: 'TOP_DOMAIN_ERROR',
      CLEAR: 'TOP_DOMAIN_CLEAR'
    }
    // COUNT: {
    //   INIT: "COUNT_INIT",
    //   SUCCESS: "COUNT_SUCCESS",
    //   ERROR: "COUNT_ERROR",
    //   CLEAR: "COUNT_CLEAR",
    // },
  },

  INCIDENT: {
    GET_INCIDENT: {
      INIT: 'GET_INCIDENT_INIT',
      SUCCESS: 'GET_INCIDENT_SUCCESS',
      ERROR: 'GET_INCIDENT_ERROR',
      CLEAR: 'GET_INCIDENT_CLEAR'
    },
    GET_INCIDENT_V2: {
      INIT: 'GET_INCIDENT_V2_INIT',
      SUCCESS: 'GET_INCIDENT_V2_SUCCESS',
      ERROR: 'GET_INCIDENT_V2_ERROR',
      CLEAR: 'GET_INCIDENT_V2_CLEAR'
    }
  },
  EXPORT_DASHBOARD: {
    COUNT_EXPORT: {
      INIT: 'COUNT_EXPORT_INIT',
      SUCCESS: 'COUNT_EXPORT_SUCCESS',
      ERROR: 'COUNT_EXPORT_ERROR',
      CLEAR: 'COUNT_EXPORT_CLEAR'
    },
    INCIDENT_VOLUME_EXPORT: {
      INIT: 'INCIDENT_VOLUME_EXPORT_INIT',
      SUCCESS: 'INCIDENT_VOLUME_EXPORT_SUCCESS',
      ERROR: 'INCIDENT_VOLUME_EXPORT_ERROR',
      CLEAR: 'INCIDENT_VOLUME_EXPORT_CLEAR'
    },
    CASE_BY_COUNTRY_EXPORT: {
      INIT: 'CASE_BY_COUNTRY_EXPORT_INIT',
      SUCCESS: 'CASE_BY_COUNTRY_EXPORT_SUCCESS',
      ERROR: 'CASE_BY_COUNTRY_EXPORT_ERROR',
      CLEAR: 'CASE_BY_COUNTRY_EXPORT_CLEAR'
    },
    CASE_BY_CHANNEL_EXPORT: {
      INIT: 'CASE_BY_CHANNEL_EXPORT_INIT',
      SUCCESS: 'CASE_BY_CHANNEL_EXPORT_SUCCESS',
      ERROR: 'CASE_BY_CHANNEL_EXPORT_ERROR',
      CLEAR: 'CASE_BY_CHANNEL_EXPORT_CLEAR'
    },
    CASE_BY_SUB_CHANNEL_EXPORT: {
      INIT: 'CASE_BY_SUB_CHANNEL_EXPORT_INIT',
      SUCCESS: 'CASE_BY_SUB_CHANNEL_EXPORT_SUCCESS',
      ERROR: 'CASE_BY_SUB_CHANNEL_EXPORT_ERROR',
      CLEAR: 'CASE_BY_SUB_CHANNEL_EXPORT_CLEAR'
    },
    TOP_LOCATION_EXPORT: {
      INIT: 'TOP_LOCATION_EXPORT_INIT',
      SUCCESS: 'TOP_LOCATION_EXPORT_SUCCESS',
      ERROR: 'TOP_LOCATION_EXPORT_ERROR',
      CLEAR: 'TOP_LOCATION_EXPORT_CLEAR'
    },
    CASE_BY_CATEGORY_EXPORT: {
      INIT: 'CASE_BY_CATEGORY_EXPORT_INIT',
      SUCCESS: 'CASE_BY_CATEGORY_EXPORT_SUCCESS',
      ERROR: 'CASE_BY_CATEGORY_EXPORT_ERROR',
      CLEAR: 'CASE_BY_CATEGORY_EXPORT_CLEAR'
    },
    CASE_BY_SUB_CATEGORY_EXPORT: {
      INIT: 'CASE_BY_SUB_CATEGORY_EXPORT_INIT',
      SUCCESS: 'CASE_BY_SUB_CATEGORY_EXPORT_SUCCESS',
      ERROR: 'CASE_BY_SUB_CATEGORY_EXPORT_ERROR',
      CLEAR: 'CASE_BY_SUB_CATEGORY_EXPORT_CLEAR'
    },
    CASE_BY_PUBLISHER_EXPORT: {
      INIT: 'CASE_BY_PUBLISHER_EXPORT_INIT',
      SUCCESS: 'CASE_BY_PUBLISHER_EXPORT_SUCCESS',
      ERROR: 'CASE_BY_PUBLISHER_EXPORT_ERROR',
      CLEAR: 'CASE_BY_PUBLISHER_EXPORT_CLEAR'
    },
    TOP_KEYWORD_EXPORT: {
      INIT: 'TOP_KEYWORD_EXPORT_INIT',
      SUCCESS: 'TOP_KEYWORD_EXPORT_SUCCESS',
      ERROR: 'TOP_KEYWORD_EXPORT_ERROR',
      CLEAR: 'TOP_KEYWORD_EXPORT_CLEAR'
    },
    TOP_DOMAIN_EXPORT: {
      INIT: 'TOP_DOMAIN_EXPORT_INIT',
      SUCCESS: 'TOP_DOMAIN_EXPORT_SUCCESS',
      ERROR: 'TOP_DOMAIN_EXPORT_ERROR',
      CLEAR: 'TOP_DOMAIN_EXPORT_CLEAR'
    }
  }
};
