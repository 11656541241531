import * as constant from "../constants/DashboardConstants";

const initialState = {};
const DashboardReducer = (
  state = initialState,
  { type, response, setSideDrawer, error }
) => {
  switch (type) {
    case constant.SET_SIDE_DRAWER:
      return {
        ...state,
        setSideDrawer: setSideDrawer,
      };

    default:
      return state;
  }
};
export default DashboardReducer;
