export const setLocalStorage = (key, varToSet) => {
  let buffer;
  if (typeof varToSet === 'string')
    buffer = Buffer.from(varToSet).toString('base64');
  else if (varToSet?.toString())
    buffer = Buffer.from(varToSet?.toString()).toString('base64');
  else throw Error('requires string or toString function is to be implemented');

  localStorage.setItem(key, buffer);
};

export const getLocalStorage = (key) => {
  const getStorage = localStorage.getItem(key);
  try {
    return getStorage ? atob(getStorage) : false;
  } catch (e) {
    return false;
  }
};

export const unsetLocalStorage = () => localStorage.clear();

export const removeLocalStorage = (key) => localStorage.removeItem(key);
