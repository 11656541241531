export const PACKAGECONST = {
  TABLE: {
    INIT: 'COMMON_PACKAGE_TABLE_INIT',
    SUCCESS: 'COMMON_PACKAGE_TABLE_SUCCESS',
    ERROR: 'COMMON_PACKAGE_TABLE_ERROR',
  },
  ONE: {
    INIT: 'COMMON_PACKAGE_ONE_INIT',
    SUCCESS: 'COMMON_PACKAGE_ONE_SUCCESS',
    ERROR: 'COMMON_PACKAGE_ONE_ERROR',
  },
  ADD: {
    INIT: 'COMMON_PACKAGE_ADD_INIT',
    SUCCESS: 'COMMON_PACKAGE_ADD_SUCCESS',
    ERROR: 'COMMON_PACKAGE_ADD_ERROR',
    CLEAR: 'COMMON_PACKAGE_ADD_CLEAR',
  },
  EDIT: {
    INIT: 'COMMON_PACKAGE_EDIT_INIT',
    SUCCESS: 'COMMON_PACKAGE_EDIT_SUCCESS',
    ERROR: 'COMMON_PACKAGE_EDIT_ERROR',
    CLEAR: 'COMMON_PACKAGE_EDIT_CLEAR',
  },
  DELETE: {
    INIT: 'COMMON_PACKAGE_DELETE_INIT',
    SUCCESS: 'COMMON_PACKAGE_DELETE_SUCCESS',
    ERROR: 'COMMON_PACKAGE_DELETE_ERROR',
    CLEAR: 'COMMON_PACKAGE_DELETE_CLEAR',
  },
};

export const USER_MANAGEMENT = {
  TABLE: {
    INIT: 'COMMON_USER_MANAGEMENT_TABLE_INIT',
    SUCCESS: 'COMMON_USER_MANAGEMENT_TABLE_SUCCESS',
    ERROR: 'COMMON_USER_MANAGEMENT_TABLE_ERROR',
  },
  ONE: {
    INIT: 'COMMON_USER_MANAGEMENT_ONE_INIT',
    SUCCESS: 'COMMON_USER_MANAGEMENT_ONE_SUCCESS',
    ERROR: 'COMMON_USER_MANAGEMENT_ONE_ERROR',
  },
  ADD: {
    INIT: 'COMMON_USER_MANAGEMENT_ADD_INIT',
    SUCCESS: 'COMMON_USER_MANAGEMENT_ADD_SUCCESS',
    ERROR: 'COMMON_USER_MANAGEMENT_ADD_ERROR',
    CLEAR: 'COMMON_USER_MANAGEMENT_ADD_CLEAR',
  },
  EDIT: {
    INIT: 'COMMON_USER_MANAGEMENT_EDIT_INIT',
    SUCCESS: 'COMMON_USER_MANAGEMENT_EDIT_SUCCESS',
    ERROR: 'COMMON_USER_MANAGEMENT_EDIT_ERROR',
    CLEAR: 'COMMON_USER_MANAGEMENT_EDIT_CLEAR',
  },
  DELETE: {
    INIT: 'COMMON_USER_MANAGEMENT_DELETE_INIT',
    SUCCESS: 'COMMON_USER_MANAGEMENT_DELETE_SUCCESS',
    ERROR: 'COMMON_USER_MANAGEMENT_DELETE_ERROR',
    CLEAR: 'COMMON_USER_MANAGEMENT_DELETE_CLEAR',
  },
};

export const UPM = {
  TABLE: {
    INIT: 'COMMON_UPM_TABLE_INIT',
    SUCCESS: 'COMMON_UPM_TABLE_SUCCESS',
    ERROR: 'COMMON_UPM_TABLE_ERROR',
  },
  ONE: {
    INIT: 'COMMON_UPM_ONE_INIT',
    SUCCESS: 'COMMON_UPM_ONE_SUCCESS',
    ERROR: 'COMMON_UPM_ONE_ERROR',
  },
  ADD: {
    INIT: 'COMMON_UPM_ADD_INIT',
    SUCCESS: 'COMMON_UPM_ADD_SUCCESS',
    ERROR: 'COMMON_UPM_ADD_ERROR',
    CLEAR: 'COMMON_UPM_ADD_CLEAR',
  },
  EDIT: {
    INIT: 'COMMON_UPM_EDIT_INIT',
    SUCCESS: 'COMMON_UPM_EDIT_SUCCESS',
    ERROR: 'COMMON_UPM_EDIT_ERROR',
    CLEAR: 'COMMON_UPM_EDIT_CLEAR',
  },
  DELETE: {
    INIT: 'COMMON_UPM_DELETE_INIT',
    SUCCESS: 'COMMON_UPM_DELETE_SUCCESS',
    ERROR: 'COMMON_UPM_DELETE_ERROR',
    CLEAR: 'COMMON_UPM_DELETE_CLEAR',
  },
  // Dropdowns
  USERS: {
    INIT: 'COMMON_UPM_USERS_LIST_INIT',
    SUCCESS: 'COMMON_UPM_USERS_LIST_SUCCESS',
    ERROR: 'COMMON_UPM_USERS_LIST_ERROR',
    CLEAR: 'COMMON_UPM_USERS_LIST_CLEAR',
  },
  PACKAGES: {
    INIT: 'COMMON_UPM_PACKAGES_LIST_INIT',
    SUCCESS: 'COMMON_UPM_PACKAGES_LIST_SUCCESS',
    ERROR: 'COMMON_UPM_PACKAGES_LIST_ERROR',
    CLEAR: 'COMMON_UPM_PACKAGES_LIST_CLEAR',
  },
  MENUS: {
    INIT: 'COMMON_UPM_MENUS_LIST_INIT',
    SUCCESS: 'COMMON_UPM_MENUS_LIST_SUCCESS',
    ERROR: 'COMMON_UPM_MENUS_LIST_ERROR',
    CLEAR: 'COMMON_UPM_MENUS_LIST_CLEAR',
  },
};
