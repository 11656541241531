import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Toggle.scss";
import { setValue } from "../../../../../../redux/MerchantLaundering/Actions";
import { getLocalStorage } from "../../../../../../Utils/const";

const ToggleButton = () => {
  const dispatch = useDispatch();
  let UPI_STATUS = localStorage.getItem("UPI_STATUS_VALUE");
  
  const [toggleState, setToggleState] = useState(UPI_STATUS === "Found" ? true: false);

  useEffect(() => {

    if (UPI_STATUS !== null){
    UPI_STATUS = localStorage.setItem("UPI_STATUS_VALUE",toggleState === true ? "Found": "Found,Not Found");
    }
    // Set the toggle state based on the value in local storage
  }, [toggleState]);

  const handleToggle = () => {
    const newValue = toggleState ? "Found" : "Found,Not Found";
    setToggleState(!toggleState);
    localStorage.setItem("UPI_STATUS_VALUE", newValue);
    dispatch(setValue(newValue));
  };

  return (
    <>
      <b className="d-flex justify-content-center">UPI Status</b>
      <div className="d-flex justify-content-center mt-1">
        <span className="toggle-label">Own</span>
        <div className="custom-control custom-switch mx-3 custom-control">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitch1"
            style={{cursor:"pointer"}}
            checked={toggleState}
            onChange={handleToggle}
          />
          <label className="custom-control-label" htmlFor="customSwitch1" style={{cursor:"pointer"}}>
            <span className="switch-icon" />
          </label>
        </div>
        <span className="toggle-label">Over All</span>
      </div>
    </>
  );
};

export default ToggleButton;
