import React, { useState, useEffect ,useContext} from "react";
import { useDispatch } from "react-redux";
import "./Toggle.scss";
import { setValue } from "../../../../../../redux/MerchantLaundering/Actions";
import { getLocalStorage } from "../../../../../../Utils/const";
import { ToggleContext } from "../../../../../../Pages/ToggleContext";
import { useToggleShowContext } from "../../../../../../Pages/ShowOrganicContext";

const ToggleButton = () => {
//   const dispatch = useDispatch();
const { showOrganic, setShowOrganic } = useToggleShowContext();
const [toggleShowState, setToggleShowState] = useState(showOrganic === true);
const { toggleState, setToggleState } = useContext(ToggleContext);

  const handleToggle = () => {
    setToggleState(!toggleState);
  };

  return (
    <>
      <b className="d-flex justify-content-center">Lea Dashboard </b>
      <div className="d-flex justify-content-center mt-1">
        <span className="toggle-label">Social Media</span>
        <div className="custom-control custom-switch mx-3 custom-control">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitch1"
            style={{cursor:"pointer"}}
            checked={toggleState}
            onChange={handleToggle}
          />
          <label className="custom-control-label" htmlFor="customSwitch1" style={{cursor:"pointer"}}>
            <span className="switch-icon" />
          </label>
        </div>
        <span className="toggle-label">Organic Search</span>
      </div>
    </>
  );
};

export default ToggleButton;
