import React from 'react';
import DatePicker from 'react-datepicker';
import { useDate } from './context';
import { Actions } from './reducer';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';

export default function DatePickerCustom({ blockFor = 18, lockFor = 18 }) {
  const { state, dispatch } = useDate();
  const minDate = new Date(moment().subtract(blockFor, 'months').startOf('month'));
  // const maxDate = new Date(moment(state.fromDate).add(lockFor, 'month'));
  const maxDate = new Date();
  function onChange(dates) {
    dispatch(Actions.setDate(dates[0], dates[1]));
  }
  function onClickOutSide() {
    if (!state.toDate) toast.error('Please select end date.');
  }
  return (
    <>
      <Toaster position='top-right' />
      <DatePicker
        dateFormat='dd/MM/yyyy'
        startDate={state.fromDate}
        endDate={state.toDate}
        onChange={onChange}
        onClickOutside={onClickOutSide}
        minDate={minDate}
        maxDate={maxDate}
        selectsRange
      />
    </>
  );
}
