import * as constant from "../constants/CommonConstants";
import { setLocalStorage } from "../../Utils/const";
import { MENU_LIST_VALUE } from "../../Utils/AUTH_CONST";
//   } from "../../../utils/helpers"

const initialState = {
  package_name: [],
  menu_list: [],
  setGlobalPackageName: [],
  setGlobalRefreshRendering: true,
};
const CommonReducer = (
  state = initialState,
  {
    type,
    response,
    error,
    setGlobalPackageName,
    setGlobalRefreshRendering,
    end = 100,
  }
) => {
  switch (type) {
    case constant.PACKAGENAME_INIT:
    case constant.MENULIST_INIT:
    case constant.PACKAGENAME_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        package_name: response?.data,
      };

    case constant.MENULIST_INIT_SUCCESS:
      setLocalStorage(MENU_LIST_VALUE.MENU, JSON.stringify(response.menus));
      return {
        ...state,
        loading: false,
        menu_list: response,
      };

    case constant.SET_GLOBAL_PACKAGE_NAME:
      return {
        ...state,
        setGlobalPackageName: setGlobalPackageName,
      };
    // REFREH
    case constant.SET_GLOBAL_REFRESH_RENDERING:
      return {
        ...state,
        setGlobalRefreshRendering: setGlobalRefreshRendering,
      };

    case constant.PACKAGENAME_INIT_ERROR:
    case constant.MENULIST_INIT_ERROR:

    default:
      return state;
  }
};
export default CommonReducer;
