import React, { createContext, useState, useContext } from 'react';


const ToggleShowContext = createContext();

export const ToggleShowProvider = ({ children }) => {
  const [showOrganic, setShowOrganic] = useState(null);

  return (
    <ToggleShowContext.Provider value={{ showOrganic, setShowOrganic }}>
      {children}
    </ToggleShowContext.Provider>
  );
};

export const useToggleShowContext = () => useContext(ToggleShowContext);