import { combineReducers } from "redux";
import CONST from "./Const";

// const initialState = {
//   ticket_details: [],
// };

function identityScanReducer(state = {}, action) {
  return Reducer(CONST.IDENTITY_SCAN[action.id], state, action);
}

function customerConfigReducer(state = {}, action) {
  return Reducer(CONST.CUSTOMER_REPORT_CONFIG[action.id], state, action);
}

function packageMappingReducer(state = {}, action) {
  return Reducer(CONST.PACKAGE_MAPPING[action.id], state, action);
}

function redmineMappingReducer(state = {}, action) {
  return Reducer(CONST.REDMINE_MAPPING[action.id], state, action);
}
function dashboardReducer(state = {}, action) {
  return Reducer(CONST.DASHBOARD[action.id], state, action);
}
function exportDashboardReducer(state = {}, action) {
  return Reducer(CONST.EXPORT_DASHBOARD[action.id], state, action);
}

function incidentReducer(state = {}, action) {
  return Reducer(CONST.INCIDENT[action.id], state, action);
}

function identityScanSignupReducer(state = {}, action) {
  return Reducer(CONST.SIGNUP[action.id], state, action);
}

function FilterReducer(state = {}, action) {
  return Reducer(CONST.FILTER[action.id], state, action);
}
function LEAFilterReducer(state = {}, action) {
  return Reducer(CONST.LEAFILTER[action.id], state, action);
}

function Search360Reducer(state = {}, action) {
  return Reducer(CONST.SEARCH_360_DEGREE[action.id], state, action);
}
function BiTicketReducer(state = {}, action) {
  return Reducer(CONST.BI_TICKET[action.id], state, action);
}
function ExportReportReducer(state = {}, action) {
  return Reducer(CONST.EXPORT_REPORT[action.id], state, action);
}

function DigitalFootprintReducer(state = {}, action) {
  return Reducer(CONST.DIGITAL_FOOTPRINT[action.id], state, action);
}

export default combineReducers({
  IdentityScan: identityScanReducer,
  IdentityScanSignup: identityScanSignupReducer,
  BiFilters: FilterReducer,
  LEAFilters:LEAFilterReducer,
  Search360: Search360Reducer,
  BiTicket: BiTicketReducer,
  ExportReport: ExportReportReducer,
  DigitalFootprint: DigitalFootprintReducer,
  customerConfig: customerConfigReducer,
  packageMapping: packageMappingReducer,
  redmineMapping: redmineMappingReducer,
  dashboard: dashboardReducer,
  incident:incidentReducer,
  exportDashboard:exportDashboardReducer

});

function Reducer(key, state, action) {
  switch (action.type) {
    case key?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.SUCCESS: {
      const data = { loading: false, data: action.data, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.CLEAR: {
      const data = { loading: false, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}
