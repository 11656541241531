export default {
  BM_TICKET: {
    GET_DETAILS_TABLE: {
      INIT: 'GET_BM_TICKET_TABLE_INIT',
      SUCCESS: 'GET_BM_TICKET_TABLE_SUCCESS',
      ERROR: 'GET_BM_TICKET_TABLE_ERROR',
      CLEAR: 'GET_BM_TICKET_TABLE_CLEAR'
    },
    UPDATE_DATA: {
      INIT: 'BM_TICKET_UPDATE_INIT',
      SUCCESS: 'GET_BM_TICKET_UPDATE_SUCCESS',
      ERROR: 'GET_BM_TICKET_UPDATE_ERROR',
      CLEAR: 'GET_BM_TICKET_UPDATE_CLEAR'
    },
    SINGLE_TICKET: {
      INIT: 'SINGLE_TICKET_INIT',
      SUCCESS: 'GET_SINGLE_TICKET_SUCCESS',
      ERROR: 'GET_SINGLE_TICKET_ERROR',
      CLEAR: 'GET_SINGLE_TICKET_CLEAR'
    },
    DOWNLOAD_TICKET: {
      INIT: 'DOWNLOAD_TICKET_INIT',
      SUCCESS: 'DOWNLOAD_TICKET_SUCCESS',
      ERROR: 'DOWNLOAD_TICKET_ERROR',
      CLEAR: 'DOWNLOAD_TICKET_CLEAR'
    },
    GET_BM_PRIORITY_LIST: {
      INIT: 'GET_BM_PRIORITY_LIST_INIT',
      SUCCESS: 'GET_BM_PRIORITY_LIST_SUCCESS',
      ERROR: 'GET_BM_PRIORITY_LIST_ERROR',
      CLEAR: 'GET_BM_PRIORITY_LIST_CLEAR'
    },
    GET_BM_STATUS_LIST: {
      INIT: 'GET_BM_STATUS_LIST_INIT',
      SUCCESS: 'GET_BM_STATUS_LIST_SUCCESS',
      ERROR: 'GET_BM_STATUS_LIST_ERROR',
      CLEAR: 'GET_BM_STATUS_LIST_CLEAR'
    },
    GET_BM_ASSIGNEE_LIST: {
      INIT: 'GET_BM_ASSIGNEE_LIST_INIT',
      SUCCESS: 'GET_BM_ASSIGNEE_LIST_SUCCESS',
      ERROR: 'GET_BM_ASSIGNEE_LIST_ERROR',
      CLEAR: 'GET_BM_ASSIGNEE_LIST_CLEAR'
    }
  },
  INCIDENT_REPORT: {
    GET_INCIDENT_REPORT: {
      INIT: 'GET_INCIDENT_REPORT_INIT',
      SUCCESS: 'GET_INCIDENT_REPORT_SUCCESS',
      ERROR: 'GET_INCIDENT_REPORT_ERROR',
      CLEAR: 'GET_INCIDENT_REPORT_CLEAR'
    },
    GET_INCIDENT_REPORT_V2: {
      INIT: 'GET_INCIDENT_REPORT_V2_INIT',
      SUCCESS: 'GET_INCIDENT_REPORT_V2_SUCCESS',
      ERROR: 'GET_INCIDENT_REPORT_V2_ERROR',
      CLEAR: 'GET_INCIDENT_REPORT_V2_CLEAR'
    }
  },
  REPORT_CONFIG: {
    GET_REPORT_CONFIG_TABLE: {
      INIT: 'BM_GET_REPORT_CONFIG_TABLE_INIT',
      SUCCESS: 'BM_GET_REPORT_CONFIG_TABLE_SUCCESS',
      ERROR: 'BM_GET_REPORT_CONFIG_TABLE_ERROR',
      CLEAR: 'BM_GET_REPORT_CONFIG_TABLE_CLEAR'
    },
    UPDATE_REPORT_CONFIG: {
      INIT: 'UPDATE_REPORT_CONFIG_INIT',
      SUCCESS: 'UPDATE_REPORT_CONFIG_SUCCESS',
      ERROR: 'UPDATE_REPORT_CONFIG_ERROR',
      CLEAR: 'UPDATE_REPORT_CONFIG_CLEAR'
    },
    SINGLE_REPORT_CONFIG: {
      INIT: 'SINGLE_REPORT_CONFIG_INIT',
      SUCCESS: 'SINGLE_REPORT_CONFIG_SUCCESS',
      ERROR: 'SINGLE_REPORT_CONFIG_ERROR',
      CLEAR: 'SINGLE_REPORT_CONFIG_CLEAR'
    },
    DELETE_REPORT_CONFIG: {
      INIT: 'DELETE_REPORT_CONFIG_INIT',
      SUCCESS: 'DELETE_REPORT_CONFIG_SUCCESS',
      ERROR: 'DELETE_REPORT_CONFIG_ERROR',
      CLEAR: 'DELETE_REPORT_CONFIG_CLEAR'
    },
    ADD_REPORT_CONFIG: {
      INIT: 'ADD_REPORT_CONFIG_INIT',
      SUCCESS: 'ADD_REPORT_CONFIG_SUCCESS',
      ERROR: 'ADD_REPORT_CONFIG_ERROR',
      CLEAR: 'ADD_REPORT_CONFIG_CLEAR'
    }
  },
  DASHBOARD: {
    COUNT_BM: {
      INIT: 'COUNT_BM_INIT',
      SUCCESS: 'COUNT_BM_SUCCESS',
      ERROR: 'COUNT_BM_ERROR',
      CLEAR: 'COUNT_BM_CLEAR'
    },
    INCIDENT_VOLUME_BM: {
      INIT: 'INCIDENT_VOLUME_INIT_BM',
      SUCCESS: 'INCIDENT_VOLUME_SUCCESS_BM',
      ERROR: 'INCIDENT_VOLUME_ERROR_BM',
      CLEAR: 'INCIDENT_VOLUME_CLEAR_BM'
    },
    CASE_BY_CHANNEL_BM: {
      INIT: 'CASE_BY_CHANNEL_INIT_BM',
      SUCCESS: 'CASE_BY_CHANNEL_SUCCESS_BM',
      ERROR: 'CASE_BY_CHANNEL_ERROR_BM',
      CLEAR: 'CASE_BY_CHANNEL_CLEAR_BM'
    },
    CASE_BY_SUB_CHANNEL_BM: {
      INIT: 'CASE_BY_SUB_CHANNEL_INIT_BM',
      SUCCESS: 'CASE_BY_SUB_CHANNEL_SUCCESS_BM',
      ERROR: 'CASE_BY_SUB_CHANNEL_ERROR_BM',
      CLEAR: 'CASE_BY_SUB_CHANNEL_CLEAR_BM'
    },
    CLOSED_PERCENTAGE_BM: {
      INIT: 'CLOSED_PERCENTAGE_INIT_BM',
      SUCCESS: 'CLOSED_PERCENTAGE_SUCCESS_BM',
      ERROR: 'CLOSED_PERCENTAGE_ERROR_BM',
      CLEAR: 'CLOSED_PERCENTAGE_CLEAR_BM'
    },
    CASE_BY_CATEGORY_BM: {
      INIT: 'CASE_BY_CATEGORY_INIT_BM',
      SUCCESS: 'CASE_BY_CATEGORY_SUCCESS_BM',
      ERROR: 'CASE_BY_CATEGORY_ERROR_BM',
      CLEAR: 'CASE_BY_CATEGORY_CLEAR_BM'
    },
    CASE_BY_SUB_CATEGORY_BM: {
      INIT: 'CASE_BY_SUB_CATEGORY_INIT_BM',
      SUCCESS: 'CASE_BY_SUB_CATEGORY_SUCCESS_BM',
      ERROR: 'CASE_BY_SUB_CATEGORY_ERROR_BM',
      CLEAR: 'CASE_BY_SUB_CATEGORY_CLEAR_BM'
    },
    CASE_BY_PUBLISHER_BM: {
      INIT: 'CASE_BY_PUBLISHER_INIT_BM',
      SUCCESS: 'CASE_BY_PUBLISHER_SUCCESS_BM',
      ERROR: 'CASE_BY_PUBLISHER_ERROR_BM',
      CLEAR: 'CASE_BY_PUBLISHER_CLEAR_BM'
    },
    TAKEDOWN_STATUS_BM: {
      INIT: 'TAKEDOWN_STATUS_INIT_BM',
      SUCCESS: 'TAKEDOWN_STATUS_SUCCESS_BM',
      ERROR: 'TAKEDOWN_STATUS_ERROR_BM',
      CLEAR: 'TAKEDOWN_STATUS_CLEAR_BM'
    },
    TAKEDOWN_PERCENT_BM: {
      INIT: 'TAKEDOWN_PERCENT_INIT_BM',
      SUCCESS: 'TAKEDOWN_PERCENT_SUCCESS_BM',
      ERROR: 'TAKEDOWN_PERCENT_ERROR_BM',
      CLEAR: 'TAKEDOWN_PERCENT_CLEAR_BM'
    },
    TOP_LOCATION_BM: {
      INIT: 'TOP_LOCATION_INIT_BM',
      SUCCESS: 'TOP_LOCATION_SUCCESS_BM',
      ERROR: 'TOP_LOCATION_ERROR_BM',
      CLEAR: 'TOP_LOCATION_CLEAR_BM'
    }
  },
  DASHBOARD_EXPORT: {
    COUNT_BM_EXPORT: {
      INIT: 'COUNT_BM_EXPORT_INIT',
      SUCCESS: 'COUNT_BM_EXPORT_SUCCESS',
      ERROR: 'COUNT_BM_EXPORT_ERROR',
      CLEAR: 'COUNT_BM_EXPORT_CLEAR'
    },
    INCIDENT_VOLUME_BM_EXPORT: {
      INIT: 'INCIDENT_VOLUME_INIT_BM_EXPORT',
      SUCCESS: 'INCIDENT_VOLUME_SUCCESS_BM_EXPORT',
      ERROR: 'INCIDENT_VOLUME_ERROR_BM_EXPORT',
      CLEAR: 'INCIDENT_VOLUME_CLEAR_BM_EXPORT'
    },
    CASE_BY_CHANNEL_BM_EXPORT: {
      INIT: 'CASE_BY_CHANNEL_INIT_BM_EXPORT',
      SUCCESS: 'CASE_BY_CHANNEL_SUCCESS_BM_EXPORT',
      ERROR: 'CASE_BY_CHANNEL_ERROR_BM_EXPORT',
      CLEAR: 'CASE_BY_CHANNEL_CLEAR_BM_EXPORT'
    },
    CASE_BY_SUB_CHANNEL_BM_EXPORT: {
      INIT: 'CASE_BY_SUB_CHANNEL_INIT_BM_EXPORT',
      SUCCESS: 'CASE_BY_SUB_CHANNEL_SUCCESS_BM_EXPORT',
      ERROR: 'CASE_BY_SUB_CHANNEL_ERROR_BM_EXPORT',
      CLEAR: 'CASE_BY_SUB_CHANNEL_CLEAR_BM_EXPORT'
    },
    CLOSED_PERCENTAGE_BM_EXPORT: {
      INIT: 'CLOSED_PERCENTAGE_INIT_BM_EXPORT',
      SUCCESS: 'CLOSED_PERCENTAGE_SUCCESS_BM_EXPORT',
      ERROR: 'CLOSED_PERCENTAGE_ERROR_BM_EXPORT',
      CLEAR: 'CLOSED_PERCENTAGE_CLEAR_BM_EXPORT'
    },
    CASE_BY_CATEGORY_BM_EXPORT: {
      INIT: 'CASE_BY_CATEGORY_INIT_BM_EXPORT',
      SUCCESS: 'CASE_BY_CATEGORY_SUCCESS_BM_EXPORT',
      ERROR: 'CASE_BY_CATEGORY_ERROR_BM_EXPORT',
      CLEAR: 'CASE_BY_CATEGORY_CLEAR_BM_EXPORT'
    },
    CASE_BY_SUB_CATEGORY_BM_EXPORT: {
      INIT: 'CASE_BY_SUB_CATEGORY_INIT_BM_EXPORT',
      SUCCESS: 'CASE_BY_SUB_CATEGORY_SUCCESS_BM_EXPORT',
      ERROR: 'CASE_BY_SUB_CATEGORY_ERROR_BM_EXPORT',
      CLEAR: 'CASE_BY_SUB_CATEGORY_CLEAR_BM_EXPORT'
    },
    CASE_BY_PUBLISHER_BM_EXPORT: {
      INIT: 'CASE_BY_PUBLISHER_INIT_BM_EXPORT',
      SUCCESS: 'CASE_BY_PUBLISHER_SUCCESS_BM_EXPORT',
      ERROR: 'CASE_BY_PUBLISHER_ERROR_BM_EXPORT',
      CLEAR: 'CASE_BY_PUBLISHER_CLEAR_BM_EXPORT'
    },
    TAKEDOWN_STATUS_BM_EXPORT: {
      INIT: 'TAKEDOWN_STATUS_INIT_BM_EXPORT',
      SUCCESS: 'TAKEDOWN_STATUS_SUCCESS_BM_EXPORT',
      ERROR: 'TAKEDOWN_STATUS_ERROR_BM_EXPORT',
      CLEAR: 'TAKEDOWN_STATUS_CLEAR_BM_EXPORT'
    },
    TOP_LOCATION_BM_EXPORT: {
      INIT: 'TOP_LOCATION_INIT_BM_EXPORT',
      SUCCESS: 'TOP_LOCATION_SUCCESS_BM_EXPORT',
      ERROR: 'TOP_LOCATION_ERROR_BM_EXPORT',
      CLEAR: 'TOP_LOCATION_CLEAR_BM_EXPORT'
    }
  },
  EXPORT_REPORT: {
    GET_DETAILS_TABLE: {
      INIT: 'EXPORT_REPORT_TABLE_INIT',
      SUCCESS: 'EXPORT_REPORT_TABLE_SUCCESS',
      ERROR: 'EXPORT_REPORT_TABLE_ERROR',
      REFRESH: 'EXPORT_REPORT_TABLE_REFRESH'
    },
    GENERATE_REPORT: {
      INIT: 'GENERATE_REPORT_INIT',
      SUCCESS: 'GENERATE_REPORT_SUCCESS',
      ERROR: 'GENERATE_REPORT_ERROR',
      CLEAR: 'GENERATE_REPORT_CLEAR'
    }
  },

  BRAND_MONITORING_EXPORT_REPORT: {
    BM_EXPORT_REPORT_TABLE: {
      INIT: 'BM_EXPORT_REPORT_TABLE_INIT',
      SUCCESS: 'BM_EXPORT_REPORT_TABLE_SUCCESS',
      ERROR: 'BM_EXPORT_REPORT_TABLE_ERROR',
      CLEAR: 'BM_EXPORT_REPORT_TABLE_CLEAR',
      REFETCH: 'BM_EXPORT_REPORT_TABLE_REFETCH'
    },

    BM_GENERATE_REPORT: {
      INIT: 'BM_GENERATE_REPORT_INIT',
      SUCCESS: 'BM_GENERATE_REPORT_SUCCESS',
      ERROR: 'BM_GENERATE_REPORT_ERROR',
      CLEAR: 'BM_GENERATE_REPORT_CLEAR'
    },
    ADDITIONAL_FILTER: {
      INIT: 'ADDITIONAL_FILTER_INIT',
      SUCCESS: 'ADDITIONAL_FILTER_SUCCESS',
      ERROR: 'ADDITIONAL_FILTER_ERROR',
      CLEAR: 'ADDITIONAL_FILTER_CLEAR'
    }
  }
};
