import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/CommonConstants";
import CommonServices from "../services/CommonService";
import { ApiUrl, EndPoints } from "../helpers/Endpoints";
import { API } from "../setupAxios";
// import { AUTH_DATA } from "../../Utils/AUTH_CONST";
// import { getLocalStorage } from "../../Utils/const";

export function* ViewPackagenameSaga() {
  try {
    const response = yield call(CommonServices.ViewPackagename);
    yield put({ type: constant.PACKAGENAME_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PACKAGENAME_INIT_ERROR, error });
  }
}

export function* ViewMenulistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.menu_list;
    const params = { package_name: payload.packageName };
    const response = yield API.get(url, { params });
    yield put({ type: constant.MENULIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MENULIST_INIT_ERROR, error });
  }
}

export default function* CommonSaga() {
  yield takeLatest(constant.PACKAGENAME_INIT, ViewPackagenameSaga);

  yield takeLatest(constant.MENULIST_INIT, ViewMenulistSaga);
}
