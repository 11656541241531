import { takeLatest, put } from 'redux-saga/effects';
import CONST from './Const';
import { API, APIDROPDOWN } from '../setupAxios';
import toast, { Toaster } from 'react-hot-toast';
import { ApiUrl } from '../helpers/Endpoints';

const signout = () => {
  localStorage.removeItem('auth_data');
  localStorage.removeItem('dpackage');
  localStorage.removeItem('startDateValue');
  localStorage.removeItem('startDate');
  localStorage.removeItem('endDate');
  window.location = '/';
};

const Endpoint = {
  ML_TICKET: {
    GET_DETAILS_TABLE: '/ticket',
    SINGLE_TICKET: '/ticket/',
    UPDATE_DATA: '/ticket',
    GET_ML_PRIORITY_LIST: '/ticket/dropdown/priority',
    GET_ML_STATUS_LIST: '/ticket/dropdown/status',
    GET_ML_ASSIGNEE_LIST: '/ticket/dropdown/assignee'
  },
  INVESTMENT_SCAN_DASHBOARD: {
    TOTAL_COUNTS: '/investment-scams/dashboard/count',
    TOTAL_INCIDENTS: '/investment-scams/dashboard/incident-volume',
    UNIQUE_UPI_COUNT: '/investment-scams/dashboard/unique-upi-count',
    UNIQUE_WEBSITE_COUNT: '/investment-scams/dashboard/unique-website-count',
    TOP_BANK: '/investment-scams/dashboard/top-bank',
    TOP_WEBSITES: '/investment-scams/dashboard/top-website',
    TOP_PAYMENT_GATEWAY: '/investment-scams/dashboard/top-payment-gateway',
    TOP_ORIGIN: '/investment-scams/dashboard/top-channel'
  },
  DASHBOARD_DOWNLOAD: {
    INCIDENT_VOLUME: '/investment-scams/dashboard/incident-volume',
    TOP10_BANKS: '/investment-scams/dashboard/top-bank',
    TOP10_WEBSITES: '/investment-scams/dashboard/top-website',
    PAYMENT_GATEWAY: '/investment-scams/dashboard/top-payment-gateway',
    WEBSITE_HOSTING_COUNTRIES: '/investment-scams/dashboard/top-origin'
  },
  EXPORT_REPOT: {
    ML_EXPORT_REPORT_TABLE: '/investment-scams/report',
    ADDITIONAL_FILTER: '/investment-scams/report/fields',
    // IS_GENERATE_REPORT: "/investment-scams/report/generate",
    ML_GENERATE_REPORT: {
      domain: ApiUrl,
      end: '/investment-scams/report/generate?type=xlsx',
      method: 'POST'
    }
  },
  AFFILIATE_MONITORING_EXPORT_REPORT: {
    AM_EXPORT_REPORT_TABLE: '/affiliate-monitoring/report',
    ADDITIONAL_FILTER: '/affiliate-monitoring/report/fields',
    // IS_GENERATE_REPORT: "/investment-scams/report/generate",
    AM_GENERATE_REPORT: {
      domain: ApiUrl,
      end: '/affiliate-monitoring/report/generate?type=xlsx',
      method: 'POST'
    }
  },
  INCIDENCES_REPORT: {
    INCIDENCES_REPORT: '/investment-scams/incidences',
    GET_ML_PRIORITY_LIST: '/ticket/dropdown/priority',
    GET_ML_STATUS_LIST: '/ticket/dropdown/status',
    GET_ML_ASSIGNEE_LIST: '/ticket/dropdown/assignee',
    GET_INCIDENCE_REPORT_1: {
      domain: ApiUrl,
      end: '/ticket/close-one',
      method: 'PUT'
    }
  },

  REPORT_CONFIG: {
    GET_REPORT_CONFIG_TABLE: '/report-config',
    SINGLE_REPORT_CONFIG: '/report-config/',
    UPDATE_REPORT_CONFIG: '/report-config',
    DELETE_REPORT_CONFIG: {
      domain: ApiUrl,
      end: '/report-config',
      method: 'DELETE'
    },
    ADD_REPORT_CONFIG: {
      domain: ApiUrl,
      end: '/report-config',
      method: 'POST'
    }
  }
};
function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE_QUERY': {
            const response = yield API.delete(url + '/' + payload.data, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'UPDATE_QUERY': {
            let posturl = url + payload.payload.id;
            const response = yield API.put(posturl, payload.payload.data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POSTFORMDATA': {
            const data = payload.data;
            const formData = new FormData();
            const csv = data.csv?.files[0];
            if (!csv) throw new Error('File not selected');
            formData.append('csv', csv, 'data.csv');
            const extra = data.extra?.files[0];
            // return {};
            if (extra) {
              formData.append('extra', extra);
            }
            const response = yield API.post(url, formData);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POSTCUSTOMHEAD': {
            const data = payload.data;

            const response = yield APIDROPDOWN.post(url);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'GET_DYNAMIC_ENDPOINT': {
            const response = yield API.get(url + '/' + params, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            const response = yield API.put(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.delete(url, { params, data });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response
        });
      }
    } catch (error) {
      console.error(payload.id, error);
      const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout();
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error
      });
    }
  };
}

export function* getISTicketData(payload) {
  try {
    if (!Endpoint.ML_TICKET[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.ML_TICKET[payload.id];
    const response = yield API.get(url, { params });

    yield put({
      type: CONST.ML_TICKET[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({
      type: CONST.ML_TICKET[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getInvestmentScamReportConfig(payload) {
  try {
    if (!Endpoint.REPORT_CONFIG[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.REPORT_CONFIG[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.REPORT_CONFIG[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.REPORT_CONFIG[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getInvestmentScamData(payload) {
  try {
    if (!Endpoint.INVESTMENT_SCAN_DASHBOARD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.INVESTMENT_SCAN_DASHBOARD[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.DASHBOARD[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.DASHBOARD[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getInvestmentScamDownloadData(payload) {
  try {
    if (!Endpoint.DASHBOARD_DOWNLOAD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.DASHBOARD_DOWNLOAD[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.DASHBOARD_DOWNLOAD[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.DASHBOARD_DOWNLOAD[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getExportReportData(payload) {
  try {
    if (!Endpoint.EXPORT_REPOT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.EXPORT_REPOT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.EXPORT_REPOT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.EXPORT_REPOT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getAmExportReportData(payload) {
  try {
    if (!Endpoint.AFFILIATE_MONITORING_EXPORT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      ApiUrl + Endpoint.AFFILIATE_MONITORING_EXPORT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.AFFILIATE_MONITORING_EXPORT_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.AFFILIATE_MONITORING_EXPORT_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getTransactionalReport(payload) {
  try {
    if (!Endpoint.EXPORT_REPOT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.EXPORT_REPOT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.EXPORT_REPOT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.EXPORT_REPOT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* getIncidenceReport(payload) {
  try {
    if (!Endpoint.INCIDENCES_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.INCIDENCES_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.INCIDENCES_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.INCIDENCES_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* GetTicketdetails(payload) {
  try {
    let queryParams = { ...payload.mutliSelectID };
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      searchParams.append(key, queryParams[key])
    );
    let url = ApiUrl + Endpoint.ML_TICKET.SINGLE_TICKET + payload.mutliSelectID;

    const response = yield API.get(url);
    yield put({ type: CONST.ML_TICKET.SINGLE_TICKET.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.ML_TICKET.SINGLE_TICKET.ERROR, error });
  }
}

export function* TicketUpdateSaga(payload) {
  try {
    let url = ApiUrl + Endpoint.ML_TICKET.UPDATE_DATA + '/' + payload.id;
    const response = yield API.put(url, payload.formData);
    if (response.status === true) {
      toast.success('Ticket Update Successfully');
    } else {
      toast.error('Something Went Wrong');
    }
    yield put({ type: CONST.ML_TICKET.UPDATE_DATA.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.ML_TICKET.UPDATE_DATA.ERROR, error });
  }
}

export function* GetReportConfigDetails(payload) {
  try {
    let queryParams = { ...payload.mutliSelectID };
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      searchParams.append(key, queryParams[key])
    );
    let url =
      ApiUrl +
      Endpoint.REPORT_CONFIG.SINGLE_REPORT_CONFIG +
      payload.mutliSelectID;

    const response = yield API.get(url);
    yield put({
      type: CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.SUCCESS,
      response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.ERROR, error });
  }
}
export function* ReportConfigUpdateSaga(payload) {
  try {
    let url =
      ApiUrl + Endpoint.REPORT_CONFIG.UPDATE_REPORT_CONFIG + '/' + payload.id;
    const response = yield API.put(url, payload.formData);
    if (response.status === true) {
      toast.success('Report Config Successfully');
    } else {
      toast.error('Something Went Wrong');
    }
    yield put({
      type: CONST.REPORT_CONFIG.UPDATE_REPORT_CONFIG.SUCCESS,
      response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.REPORT_CONFIG.UPDATE_REPORT_CONFIG.ERROR, error });
  }
}

export default function* InvestmentScamSaga() {
  yield takeLatest(CONST.ML_TICKET.GET_DETAILS_TABLE.INIT, getISTicketData);
  yield takeLatest(CONST.ML_TICKET.GET_ML_ASSIGNEE_LIST.INIT, getISTicketData);
  yield takeLatest(CONST.ML_TICKET.GET_ML_PRIORITY_LIST.INIT, getISTicketData);
  yield takeLatest(CONST.ML_TICKET.GET_ML_STATUS_LIST.INIT, getISTicketData);
  yield takeLatest(CONST.ML_TICKET.UPDATE_DATA.INIT, TicketUpdateSaga);
  yield takeLatest(CONST.ML_TICKET.SINGLE_TICKET.INIT, GetTicketdetails);

  yield takeLatest(
    CONST.REPORT_CONFIG.GET_REPORT_CONFIG_TABLE.INIT,
    getInvestmentScamReportConfig
  );
  yield takeLatest(
    CONST.REPORT_CONFIG.UPDATE_REPORT_CONFIG.INIT,
    ReportConfigUpdateSaga
  );
  yield takeLatest(
    CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.INIT,
    GetReportConfigDetails
  );

  yield takeLatest(
    CONST?.DASHBOARD?.TOTAL_COUNTS?.INIT,
    getInvestmentScamData
  );
  yield takeLatest(
    CONST.DASHBOARD.TOTAL_INCIDENTS.INIT,
    getInvestmentScamData
  );
  yield takeLatest(
    CONST.DASHBOARD.UNIQUE_UPI_COUNT.INIT,
    getInvestmentScamData
  );
  yield takeLatest(
    CONST.DASHBOARD.TOP_WEBSITES.INIT,
    getInvestmentScamData
  );
  yield takeLatest(
    CONST.REPORT_CONFIG.DELETE_REPORT_CONFIG.INIT,
    sagaGenerator(
      Endpoint.REPORT_CONFIG.DELETE_REPORT_CONFIG,
      CONST.REPORT_CONFIG.DELETE_REPORT_CONFIG
    )
  );
  yield takeLatest(
    CONST.DASHBOARD.UNIQUE_WEBSITE_COUNT.INIT,
    getInvestmentScamData
  );
  yield takeLatest(
    CONST.DASHBOARD.TOP_PAYMENT_GATEWAY.INIT,
    getInvestmentScamData
  );
  yield takeLatest(CONST.DASHBOARD.TOP_ORIGIN.INIT, getInvestmentScamData);
  yield takeLatest(CONST.DASHBOARD.TOP_BANK.INIT, getInvestmentScamData);
  yield takeLatest(
    CONST.EXPORT_REPOT.ADDITIONAL_FILTER.INIT,
    getExportReportData
  );
  yield takeLatest(
    CONST.EXPORT_REPOT.ML_EXPORT_REPORT_TABLE.INIT,
    getExportReportData
  );
  yield takeLatest(
    CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_EXPORT_REPORT_TABLE.INIT,
    getAmExportReportData
  );
  yield takeLatest(
    CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_EXPORT_REPORT_TABLE.REFETCH,
    getAmExportReportData
  );
  yield takeLatest(
    CONST.EXPORT_REPOT.ML_EXPORT_REPORT_TABLE.REFETCH,
    getExportReportData
  );
  yield takeLatest(
    CONST.EXPORT_REPOT.ML_GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.EXPORT_REPOT.ML_GENERATE_REPORT,
      CONST.EXPORT_REPOT.ML_GENERATE_REPORT
    )
  );
  yield takeLatest(
    CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.AFFILIATE_MONITORING_EXPORT_REPORT.AM_GENERATE_REPORT,
      CONST.AFFILIATE_MONITORING_EXPORT_REPORT.AM_GENERATE_REPORT
    )
  );
  yield takeLatest(
    CONST.INCIDENCES_REPORT.GET_INCIDENCE_REPORT_1.INIT,
    sagaGenerator(
      Endpoint.INCIDENCES_REPORT.GET_INCIDENCE_REPORT_1,
      CONST.INCIDENCES_REPORT.GET_INCIDENCE_REPORT_1
    )
  );
  yield takeLatest(
    CONST.REPORT_CONFIG.ADD_REPORT_CONFIG.INIT,
    sagaGenerator(
      Endpoint.REPORT_CONFIG.ADD_REPORT_CONFIG,
      CONST.REPORT_CONFIG.ADD_REPORT_CONFIG
    )
  );
  yield takeLatest(
    CONST.INCIDENCES_REPORT.INCIDENCES_REPORT.INIT,
    getIncidenceReport
  );
  yield takeLatest(
    CONST.INCIDENCES_REPORT.GET_ML_ASSIGNEE_LIST.INIT,
    getIncidenceReport
  );
  yield takeLatest(
    CONST.INCIDENCES_REPORT.GET_ML_PRIORITY_LIST.INIT,
    getIncidenceReport
  );
  yield takeLatest(
    CONST.INCIDENCES_REPORT.GET_ML_STATUS_LIST.INIT,
    getIncidenceReport
  );
  yield takeLatest(
    CONST.DASHBOARD_DOWNLOAD.INCIDENT_VOLUME.INIT,
    getInvestmentScamDownloadData
  );
  yield takeLatest(
    CONST.DASHBOARD_DOWNLOAD.TOP10_BANKS.INIT,
    getInvestmentScamDownloadData
  );
  yield takeLatest(
    CONST.DASHBOARD_DOWNLOAD.TOP10_WEBSITES.INIT,
    getInvestmentScamDownloadData
  );
  yield takeLatest(
    CONST.DASHBOARD_DOWNLOAD.WEBSITE_HOSTING_COUNTRIES.INIT,
    getInvestmentScamDownloadData
  );
  yield takeLatest(
    CONST.DASHBOARD_DOWNLOAD.PAYMENT_GATEWAY.INIT,
    getInvestmentScamDownloadData
  );
}
