import { getLocalStorage } from '../../Utils/const';
import { AUTH_DATA } from '../../Utils/AUTH_CONST';
import { DNSLIST } from '../services/dns';

const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));

const EndPoints = {
  package_name: `/user-management/packages`,
  menu_list: `/user-management/menus`,
  login: `/user-management/login`,
  change: `/user-management/change-password`,
  identity_input_dropdown: `/nodes`,
  identity_post_input: `/`,
  identity_table_get: `/jobs`,
  identity_table_get_job_details: `/status`,
  user_management: `/user-management/`,
  // lea_dashboard_url:``
};
const LEAApiUrl = DNSLIST.DASHBOARD_LEA.HOME;
const ApiUrl = DNSLIST.DASHBOARD_360.HOME;
const IdentityUrl = DNSLIST.IDSCAN.DEFAULT;
const IdentityBulkUrl = DNSLIST.IDSCAN.BULK_UPLOAD;
const Initiate = DNSLIST.IDSCAN.SCHEDULE;

export { EndPoints, ApiUrl, IdentityUrl, Initiate, IdentityBulkUrl,LEAApiUrl };
