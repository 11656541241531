import { takeLatest, put } from 'redux-saga/effects';
import CONST from './Const';
import { API, APIDROPDOWN } from '../setupAxios';
import toast, { Toaster } from 'react-hot-toast';
import { ApiUrl } from '../helpers/Endpoints';

const signout = () => {
  localStorage.removeItem('auth_data');
  localStorage.removeItem('dpackage');
  localStorage.removeItem('startDateValue');
  localStorage.removeItem('startDate');
  localStorage.removeItem('endDate');
  window.location = '/';
};
const Endpoint = {
  BM_TICKET: {
    GET_DETAILS_TABLE: '/ticket',
    SINGLE_TICKET: '/ticket/',
    UPDATE_DATA: '/ticket',
    GET_BM_PRIORITY_LIST: '/ticket/dropdown/priority',
    GET_BM_STATUS_LIST: '/ticket/dropdown/status',
    GET_BM_ASSIGNEE_LIST: '/ticket/dropdown/assignee'
  },

  INCIDENT_REPORT: {
    GET_INCIDENT_REPORT: '/brand-monitoring/incidences',
    GET_INCIDENT_REPORT_V2: '/brand-monitoring/incidences-v2'
  },
  REPORT_CONFIG: {
    GET_REPORT_CONFIG_TABLE: '/report-config',
    SINGLE_REPORT_CONFIG: '/report-config/',
    UPDATE_REPORT_CONFIG: '/report-config',
    DELETE_REPORT_CONFIG: {
      domain: ApiUrl,
      end: '/report-config',
      method: 'DELETE'
    },
    ADD_REPORT_CONFIG: {
      domain: ApiUrl,
      end: '/report-config',
      method: 'POST'
    }
  },
  DASHBOARD: {
    COUNT_BM: '/count',
    INCIDENT_VOLUME_BM: '/incident-volume',
    CASE_BY_CHANNEL_BM: '/case-by-channel',
    CASE_BY_SUB_CHANNEL_BM: '/case-by-sub-channel',
    CASE_BY_CATEGORY_BM: '/case-by-category',
    CASE_BY_SUB_CATEGORY_BM: '/case-by-sub-category',
    CASE_BY_PUBLISHER_BM: '/publisher-level-count',
    CLOSED_PERCENTAGE_BM: '/closed-percent',
    TAKEDOWN_STATUS_BM: '/takedown-count',
    TAKEDOWN_PERCENT_BM: '/takedown-percent',
    TOP_LOCATION_BM: '/top-location'
  },
  DASHBOARD_EXPORT: {
    COUNT_BM_EXPORT: '/count',
    INCIDENT_VOLUME_BM_EXPORT: '/incident-volume',
    CASE_BY_CHANNEL_BM_EXPORT: '/case-by-channel',
    CASE_BY_SUB_CHANNEL_BM_EXPORT: '/case-by-sub-channel',
    CASE_BY_CATEGORY_BM_EXPORT: '/case-by-category',
    CASE_BY_SUB_CATEGORY_BM_EXPORT: '/case-by-sub-category',
    CASE_BY_PUBLISHER_BM_EXPORT: '/publisher-level-count',
    CLOSED_PERCENTAGE_BM_EXPORT: '/closed-percent',
    TAKEDOWN_STATUS_BM_EXPORT: '/takedown-count',
    TOP_LOCATION_BM_EXPORT: '/top-location'
  },
  EXPORT_REPORT: {
    GET_DETAILS_TABLE: '/brand-monitoring/report',
    GENERATE_REPORT: {
      domain: ApiUrl,
      end: '/brand-monitoring/report/generate',
      method: 'POST'
    }
  },
  BRAND_MONITORING_EXPORT_REPORT: {
    BM_EXPORT_REPORT_TABLE: '/brand-monitoring/report',
    ADDITIONAL_FILTER: '/brand-monitoring/report/fields',
    // ML_GENERATE_REPORT: "/merchant-laundering/report/generate",
    BM_GENERATE_REPORT: {
      domain: ApiUrl,
      end: '/brand-monitoring/report/generate?type=xlsx',
      method: 'POST'
    }
  }
};
export function* getAmExportReportData(payload) {
  try {
    if (!Endpoint.BRAND_MONITORING_EXPORT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.BRAND_MONITORING_EXPORT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BRAND_MONITORING_EXPORT_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.BRAND_MONITORING_EXPORT_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getBMTicketData(payload) {
  try {
    if (!Endpoint.BM_TICKET[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.BM_TICKET[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.BM_TICKET[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.BM_TICKET[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* GetTicketdetails(payload) {
  try {
    let queryParams = { ...payload.mutliSelectID };
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      searchParams.append(key, queryParams[key])
    );
    let url = ApiUrl + Endpoint.BM_TICKET.SINGLE_TICKET + payload.mutliSelectID;

    const response = yield API.get(url);
    yield put({ type: CONST.BM_TICKET.SINGLE_TICKET.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.BM_TICKET.SINGLE_TICKET.ERROR, error });
  }
}

export function* getIncidentData(payload) {
  try {
    if (!Endpoint.INCIDENT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.INCIDENT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.INCIDENT_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.INCIDENT_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getIncidentV2Data(payload) {
  try {
    console.log(payload.id);
    if (!Endpoint.INCIDENT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.INCIDENT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.INCIDENT_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.INCIDENT_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getMerchantLaunderingReportConfig(payload) {
  try {
    if (!Endpoint.REPORT_CONFIG[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.REPORT_CONFIG[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.REPORT_CONFIG[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.REPORT_CONFIG[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}
export function* getDashboardData(payload) {
  try {
    if (!Endpoint.DASHBOARD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      ApiUrl + '/brand-monitoring/dashboard' + Endpoint.DASHBOARD[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.DASHBOARD[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.DASHBOARD[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* getDashboardExportData(payload) {
  try {
    if (!Endpoint.DASHBOARD_EXPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      ApiUrl +
      '/brand-monitoring/dashboard' +
      Endpoint.DASHBOARD_EXPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.DASHBOARD_EXPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.DASHBOARD_EXPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

export function* TicketUpdateSaga(payload) {
  try {
    let url = ApiUrl + Endpoint.BM_TICKET.UPDATE_DATA + '/' + payload.id;
    const response = yield API.put(url, payload.formData);
    if (response.status === true) {
      toast.success('Ticket Update Successfully');
    } else {
      toast.error('Something Went Wrong');
    }
    yield put({ type: CONST.BM_TICKET.UPDATE_DATA.SUCCESS, response });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.BM_TICKET.UPDATE_DATA.ERROR, error });
  }
}
export function* GetReportConfigDetails(payload) {
  try {
    let queryParams = { ...payload.mutliSelectID };
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      searchParams.append(key, queryParams[key])
    );
    let url =
      ApiUrl +
      Endpoint.REPORT_CONFIG.SINGLE_REPORT_CONFIG +
      payload.mutliSelectID;

    const response = yield API.get(url);
    yield put({
      type: CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.SUCCESS,
      response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    yield put({ type: CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.ERROR, error });
  }
}

export function* getExportReportData(payload) {
  try {
    if (!Endpoint.EXPORT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.EXPORT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.EXPORT_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response
    });
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    statusCode === 401 && signout();
    console.error(payload.id, error);
    yield put({
      type: CONST.EXPORT_REPORT[payload.id].ERROR,
      id: payload.id,
      error
    });
  }
}

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE_QUERY': {
            const response = yield API.delete(url + '/' + payload.data, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'UPDATE_QUERY': {
            let posturl = url + payload.payload.id;
            const response = yield API.put(posturl, payload.payload.data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POSTFORMDATA': {
            const data = payload.data;
            const formData = new FormData();
            const csv = data.csv?.files[0];
            if (!csv) throw new Error('File not selected');
            formData.append('csv', csv, 'data.csv');
            const extra = data.extra?.files[0];
            // return {};
            if (extra) {
              formData.append('extra', extra);
            }
            const response = yield API.post(url, formData);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POSTCUSTOMHEAD': {
            const data = payload.data;

            const response = yield APIDROPDOWN.post(url);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'GET_DYNAMIC_ENDPOINT': {
            const response = yield API.get(url + '/' + params, {});
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            const response = yield API.put(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.delete(url, { params, data });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response
        });
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      statusCode === 401 && signout();
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error
      });
    }
  };
}
export default function* BrandMonitoringSaga() {
  yield takeLatest(CONST.BM_TICKET.GET_DETAILS_TABLE.INIT, getBMTicketData);
  yield takeLatest(CONST.BM_TICKET.GET_BM_ASSIGNEE_LIST.INIT, getBMTicketData);
  yield takeLatest(CONST.BM_TICKET.GET_BM_PRIORITY_LIST.INIT, getBMTicketData);
  yield takeLatest(CONST.BM_TICKET.GET_BM_STATUS_LIST.INIT, getBMTicketData);
  yield takeLatest(CONST.BM_TICKET.UPDATE_DATA.INIT, TicketUpdateSaga);
  yield takeLatest(CONST.BM_TICKET.SINGLE_TICKET.INIT, GetTicketdetails);
  yield takeLatest(
    CONST.INCIDENT_REPORT.GET_INCIDENT_REPORT.INIT,
    getIncidentData
  );
  yield takeLatest(
    CONST.INCIDENT_REPORT.GET_INCIDENT_REPORT_V2.INIT,
    getIncidentV2Data
  );
  yield takeLatest(
    CONST.REPORT_CONFIG.GET_REPORT_CONFIG_TABLE.INIT,
    getMerchantLaunderingReportConfig
  );
  yield takeLatest(
    CONST.REPORT_CONFIG.SINGLE_REPORT_CONFIG.INIT,
    GetReportConfigDetails
  );
  for (let key in CONST.DASHBOARD) {
    if (CONST.DASHBOARD.hasOwnProperty(key)) {
      yield takeLatest(CONST.DASHBOARD[key].INIT, getDashboardData);
    }
  }
  for (let key in CONST.DASHBOARD_EXPORT) {
    if (CONST.DASHBOARD_EXPORT.hasOwnProperty(key)) {
      yield takeLatest(
        CONST.DASHBOARD_EXPORT[key].INIT,
        getDashboardExportData
      );
    }
  }
  yield takeLatest(
    CONST.EXPORT_REPORT.GET_DETAILS_TABLE.INIT,
    getExportReportData
  );
  yield takeLatest(
    CONST.EXPORT_REPORT.GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.EXPORT_REPORT.GENERATE_REPORT,
      CONST.EXPORT_REPORT.GENERATE_REPORT
    )
  );
  yield takeLatest(
    CONST.BRAND_MONITORING_EXPORT_REPORT.BM_EXPORT_REPORT_TABLE.INIT,
    getAmExportReportData
  );
  yield takeLatest(
    CONST.BRAND_MONITORING_EXPORT_REPORT.BM_EXPORT_REPORT_TABLE.REFETCH,
    getAmExportReportData
  );
  yield takeLatest(
    CONST.BRAND_MONITORING_EXPORT_REPORT.BM_GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.BRAND_MONITORING_EXPORT_REPORT.BM_GENERATE_REPORT,
      CONST.BRAND_MONITORING_EXPORT_REPORT.BM_GENERATE_REPORT
    )
  );
}
