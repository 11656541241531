import CONST from "./Const";

const initialState = {
  ticket_details: [],
};

const ReportReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case CONST.BI_TICKET.SINGLE_TICKET.INIT:
      return {
        ...state,
        ticket_details_loading: true,
      };
    case CONST.BI_TICKET.SINGLE_TICKET.SUCCESS:
      return {
        ...state,
        ticket_details_loading: false,
        ticket_details: response,
      };
    case CONST.BI_TICKET.SINGLE_TICKET.ERROR:
      return {
        ...state,
        ticket_details_loading: false,
        error: response,
      };
    case CONST.BI_TICKET.SINGLE_TICKET.CLEAR:
      return {
        ...state,
        error: {},
        ticket_details: [],
      };

    default:
      return state;
  }
};
export default ReportReducer;
